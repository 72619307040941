import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Game, GamesCollageFeature, GamesCollageFeatureTarget, Maybe, PbsKidsTheme } from '@/types/pbskids-graph';
import GameCard from '@/components/modules/GameCard';
import ThemedModule from '@/components/global/ThemedModule';
import UnderConstruction from '@/components/base/UnderConstruction';
import styles from './GamesCollage.module.scss';
import Image from 'next/image';

interface Props {
  enableLookInside?: boolean,
  featuredItem?: GamesCollageFeature,
  games: Game[],
  heading?: string,
  headingIcon?: string,
  listId?: string,
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>
}

enum FeatureSize {
  twoByTwo = '2x2',
  twoByOne = '2x1',
  oneByTwo = '1x2',
  oneByOne = '1x1',
}

const setSizeRelatedData = (featureSize: string) => {
  switch (featureSize) {
    case FeatureSize.twoByTwo:
      return {
        maxGamesToDisplay: 8,
        featuredItemClass: 'twoByTwo',
        aspectRatioClass: 'aspectCollageTwoByTwo',
      };
    case FeatureSize.twoByOne:
      return {
        maxGamesToDisplay: 10,
        featuredItemClass: 'twoByOne',
        aspectRatioClass: 'aspectCollageTwoByOne',
      };
    case FeatureSize.oneByTwo:
      return {
        maxGamesToDisplay: 10,
        featuredItemClass: 'oneByTwo',
        aspectRatioClass: 'aspectCollageOneByTwo',
      };
    case FeatureSize.oneByOne:
    default:
      return {
        maxGamesToDisplay: 12,
        featuredItemClass: '',
        aspectRatioClass: '',
      };
  }
};

const buildGamesArray = (games: Game[], limit: number) => {
  if (games?.length && limit && (games.length > limit)) {
    return games.slice(0, limit);
  } else return games;
};

const validateFeature = (featuredItem: GamesCollageFeature | undefined) => {
  return featuredItem &&
      (featuredItem.interactiveElement?.[0]?.interactiveElementImage?.[0]?.url ||
      featuredItem?.target?.[0]?.__typename === 'Game');
};

const buildFeatureData = (featuredItem: GamesCollageFeature | undefined) => {
  if (featuredItem) {
    const size = featuredItem.interactiveElement?.[0]?.defaultSize as string;
    const sizeRelatedData = setSizeRelatedData(size);
    const isValid = validateFeature(featuredItem);

    return {
      altText: featuredItem.interactiveElement?.[0]?.interactiveElementImage?.[0]?.altText as string,
      aspectRatioClass: sizeRelatedData.aspectRatioClass,
      behavior: featuredItem.interactiveElement?.[0]?.defaultBehavior as string,
      class: sizeRelatedData.featuredItemClass,
      image: featuredItem.interactiveElement?.[0]?.interactiveElementImage?.[0]?.url as string,
      isValid: isValid,
      maxGamesToDisplay: sizeRelatedData.maxGamesToDisplay,
      position: featuredItem.position as string || 'start',
      size: size || '1x1',
      target: featuredItem.target?.[0] as GamesCollageFeatureTarget,
    };
  }
};

const GamesCollage: React.FC<Props> = ({
  enableLookInside,
  featuredItem,
  games,
  heading,
  headingIcon,
  listId,
  theme,
}) => {
  const featuredItemData = useMemo(() => buildFeatureData(featuredItem), [ featuredItem ]);
  const featurePositionClass = styles[featuredItem?.position as keyof typeof styles];
  const featureSizeClass = styles[featuredItemData?.class as keyof typeof styles];

  const imageSizes = '(min-width: 1024px) 225px, (min-width: 768px) 300px, 360px';
  const featuredGameImageSizes = '(min-width: 1024px) 487px, (min-width: 768px) 630px, 710px';

  const [ collageList, setCollageList ] = useState<(GamesCollageFeature | Game)[]>();

  const buildCollage = useCallback((gamesList: Game[], feature: GamesCollageFeature | undefined) => {
    const list: (Game | GamesCollageFeature)[] = gamesList.map((obj) => ({ ...obj }));

    if (feature && featuredItemData && featuredItemData.isValid) {
      const position = featuredItemData.position;
      const size = featuredItemData.size;

      if (size === (FeatureSize.twoByTwo || FeatureSize.twoByOne)) {
        if (position === 'end') {
          list.splice(2, 0, feature);
        } else if (position === 'middle') {
          list.splice(1, 0, feature);
        } else {
          list.unshift(feature);
        }
      } else if (size === FeatureSize.oneByTwo) {
        if (position === 'end') {
          list.splice(3, 0, feature);
        } else if (position === 'middle') {
          list.splice(1, 0, feature);
        } else {
          list.unshift(feature);
        }
      } else {
        list.unshift(feature);
      }
      return list;
    }

    return list;
  }, [ featuredItemData ]);

  useEffect(() => {
    if (!games?.length) return;
    const gamesDisplayLimit = featuredItemData && featuredItemData.isValid ? featuredItemData.maxGamesToDisplay : 12;
    const gamesArr = buildGamesArray(games, gamesDisplayLimit);
    setCollageList(featuredItem ? buildCollage(gamesArr, featuredItem) : gamesArr);
  }, [ buildCollage, featuredItemData, games, featuredItem ]);

  const populateListItem = (item: Game | GamesCollageFeature) => {
    if (item.__typename === 'Game') {
      return <GameCard
        enableLookInside={ enableLookInside }
        gameItem={ item }
        sizes={ imageSizes }
      ></GameCard>;
    } else if (featuredItem && featuredItemData && item.__typename === 'GamesCollageFeature') {
      if ( featuredItemData?.target?.__typename === 'Game' && !featuredItemData.image ) {
        return <GameCard
          enableLookInside={ enableLookInside }
          classes={ featuredItemData.aspectRatioClass }
          gameItem={ featuredItemData.target }
          sizes={ featuredGameImageSizes }
        ></GameCard>;
      } else {
        // This will return other Featured Item types in the near future
        return <div className={featuredItemData.aspectRatioClass }>
          <UnderConstruction
            striped={ true }
            title={ 'Under Construction 👷'}
          >
          </UnderConstruction>
        </div>;
      }
    }

    return <></>;
  };

  const CollageList = () => {
    if (collageList?.length) {
      return <>
        {collageList.map((item: Game | GamesCollageFeature, index: number) => (
          <li
            key={ index }
            className={`${styles.gamesListItem} ${(featuredItem && item?.__typename === 'GamesCollageFeature') ? featureSizeClass : ''}`}
          >
            {populateListItem(item)}
          </li>
        ))}
      </>;
    } else return <></>;
  };

  return (!!collageList?.length) && <ThemedModule theme={theme} backgroundMode='pattern'>
    <section className={styles.gamesGrid}>
      <div className={styles.iconHeaderWrapper}>
        {headingIcon &&
          <Image
            src={headingIcon}
            alt={''}
            className={styles.iconStyling}
            width={60}
            height={60}
          />
        }
        <h2>
          { heading }
        </h2>
      </div>

      <ul
        data-ga-view-list-module={listId}
        className={`${styles.gamesList} ${featuredItem && featurePositionClass ? featurePositionClass: ''}`}
      >
        <CollageList/>
      </ul>
    </section>
  </ThemedModule>;
};

export default GamesCollage;
