import React from 'react';
import { Game, Maybe, PbsKidsTheme } from '@/types/pbskids-graph';
import GameCard from '@/components/modules/GameCard';
import ThemedModule from '@/components/global/ThemedModule';
import styles from './GameSet.module.scss';
import Image from 'next/image';

interface Props {
  games?: Game[],
  heading?: string,
  headingIcon?: string,
  listId?: string,
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>,
}

const imageSizes = '(min-width: 1024px) 225px, (min-width: 768px) 300px, 360px';

const GameSet: React.FC<Props> = ({
  games,
  heading,
  headingIcon,
  listId,
  theme,
}) => {
  const GamesList = () => {
    if (games?.length) {
      return <>
        {games.map((item: Game, index: number) => (
          <li
            key={ index }
          >
            <GameCard
              gameItem={ item }
              sizes={ imageSizes }
            ></GameCard>
          </li>
        ))}
      </>;
    } else return <></>;
  };

  return ( games && games.length ) && <ThemedModule theme={theme} backgroundMode='pattern'>
    <section className={styles.gamesGrid}>
      <div className={styles.iconHeaderWrapper}>
        {headingIcon &&
          <Image
            src={headingIcon}
            alt={''}
            className={styles.iconStyling}
            width={60}
            height={60}
          />
        }
        <h2>
          { heading }
        </h2>
      </div>

      <ul
        data-ga-view-list-module={listId}
        className={ styles.gamesList }
      >
        <GamesList/>
      </ul>
    </section>
  </ThemedModule>;
};

export default GameSet;
