import React, { useRef } from 'react';

interface DragInput {
  enabled: boolean,
  onCarouselClick: (e: React.MouseEvent) => void,
  onDragLeft: () => void,
  onDragRight: () => void,
}

interface DragOutput {
  onClick: (e: React.MouseEvent) => void,
  onMouseDown: (e: React.MouseEvent) => void,
}

const useMouseDrag = (input: DragInput): DragOutput|null => {
  const mouseUpAfterDrag = useRef<boolean>(false);

  if (!input.enabled) return null;

  let mouseDownX = 0;
  let mouseUpX = 0;
  let mouseDownY = 0;
  let mouseUpY = 0;

  const minDragDistance = 50;

  const onClick = (ev: React.MouseEvent) => {
    if (!mouseUpAfterDrag.current) {
      input.onCarouselClick(ev);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    mouseUpX = e.clientX;
  };

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);

    if (!mouseDownX || !mouseUpX) return;

    const distanceX = mouseDownX - mouseUpX;
    const distanceY = mouseDownY - mouseUpY;
    const isLeftDrag = distanceX > minDragDistance;
    const isRightDrag = distanceX < -minDragDistance;
    if (isLeftDrag && distanceX > distanceY) {
      mouseUpAfterDrag.current = true;
      input.onDragLeft();
    }
    if (isRightDrag && Math.abs(distanceX) > distanceY) {
      mouseUpAfterDrag.current = true;
      input.onDragRight();
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    mouseUpAfterDrag.current = false;
    mouseUpX = 0;
    mouseDownX = e.clientX;
    mouseDownY = 0;
    mouseUpY = e.clientY;

    document.addEventListener('mouseup', onMouseUp, { once: true });
    document.addEventListener('mousemove', onMouseMove);
  };

  return {
    onClick,
    onMouseDown,
  };
};

export default useMouseDrag;
