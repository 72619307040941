import { ReactNode } from 'react';
import styles from './UnderConstruction.module.scss';

type UnderConstructionProps = {
  children?: ReactNode,
  height?: number|string,
  striped?: boolean,
  title?: string,
  width?: number|string,
};

export default function UnderConstruction({ children, height, striped, title, width }: UnderConstructionProps) {
  return (
    <div
      className={styles.wrapper + (striped === true ? ' ' + styles.striped : '')}
      style={{ height, width }}
    >
      <span className={styles.title}>
        { title }
      </span>
      { children }
    </div>
  );
}
