import { TouchEvent, useRef } from 'react';

interface SwipeInput {
  enabled?: boolean,
  onSwipedLeft: () => void,
  onSwipedRight: () => void,
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void,
  onTouchMove: (e: TouchEvent) => void,
  onTouchEnd: (e: TouchEvent) => void,
}

const useSwipe = (input: SwipeInput): SwipeOutput|null => {
  const touchStartX = useRef<number>(0);

  if (!input.enabled) return null;

  let touchEndX = 0;
  let touchStartY = 0;
  let touchEndY = 0;

  const minSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    touchEndX = 0;
    touchStartX.current = e.targetTouches[0].clientX;
    touchStartY = 0;
    touchEndY = e.targetTouches[0].clientY;
  };

  const onTouchMove = (e: TouchEvent) => {
    touchEndX = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStartX || !touchEndX) return;
    const distanceX = touchStartX.current - touchEndX;
    const distanceY = touchStartY - touchEndY;
    const isLeftSwipe = distanceX > minSwipeDistance;
    const isRightSwipe = distanceX < -minSwipeDistance;
    if (isLeftSwipe && distanceX > distanceY) {
      input.onSwipedLeft();
    }
    if (isRightSwipe && Math.abs(distanceX) > distanceY) {
      input.onSwipedRight();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default useSwipe;
